import React from "react";
import "./styles.scss";

export default function Button({ primary, children, clickHandler}) {
	return (
		<button className={primary ? "btn primary" : "btn secondary"} onClick={clickHandler}>
			{children}
		</button>
	);
}
