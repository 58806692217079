import React, { useRef } from "react";
import Layout from "../components/layout"
import "../scss/global.scss";
import Pricing from "../components/pricing/pricing";
import { Helmet } from 'react-helmet';

const AboutPage = () => {
    const rootNode = useRef(null);
    return (
        <Layout isHome={false}>
            <Helmet>
                <title>Managers App Pricing</title>
                <meta name="description" content="You can get started with Managers App for FREE. No long term contracts, you can cancel anytime. No credit card required."></meta>
            </Helmet>
            <div className={"container"}>
                <div className={"targets"}>
                    <div className={"row"}>
                        <div className="theme--default" ref={rootNode}>
                            <Pricing />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AboutPage