import React from "react";
import Card from "../card/card";
// import Slider from "../slider/slider";
import plans from "./plans";
import "./styles.scss";

export default function Pricing() {
	// const [planPeriod, setPlanPeriod] = useState("monthly");
	const planPeriod = "monthly";

	// function togglePeriod(sliderValue) {
	// 	sliderValue ? setPlanPeriod("monthly") : setPlanPeriod("annually");
	// }

	return (
		<div className="pricing">
			<h1 style={{ color: "#8953b7", fontWeight: "normal"}}>Subscription Plans</h1>
			<h3 style={{ paddingLeft: "20%", paddingRight: "20%", fontSize: "22px" }}>Try for free. Pick a plan later. No credit card required.</h3>
			{/* <Slider onText="Monthly" offText="Annually" setValue={togglePeriod} /> */}
			<div className="card-container">
				<Card
					position="left"
					title={plans.basic.title}
					price={
						planPeriod === "monthly"
							? plans.basic.price.monthly
							: plans.basic.price.annually
					}
					items={plans.basic.benefits}
					showDollar={false}
					free={true}
				/>
				<Card
					primary
					title={plans.professional.title}
					price={
						planPeriod === "monthly"
							? plans.professional.price.monthly
							: plans.professional.price.annually
					}
					items={plans.professional.benefits}
					showDollar={true}
					free={false}
				/>
				<Card
					position="right"
					title={plans.master.title}
					price={
						planPeriod === "monthly"
							? plans.master.price.monthly
							: plans.master.price.annually
					}
					items={plans.master.benefits}
					showDollar={false}
					free={false}
					email={"contact@managers.app"}
				/>
			</div>
		</div>
	);
}
