export default {
	basic: {
		title: "ManagersApp Team",
		price: {
			monthly: "FREE",
			free: true
		},
		benefits: [
			"Up to 6 team members",
			"Access to All Modules",
			"Basic Support",
		],
	},
	professional: {
		title: "ManagersApp Business",
		price: {
			monthly: "49.99/mo",
			free: false
		},
		benefits: [
			"Up to 30 team members",
			"Access to All Modules",
			"Priority Support",
		],
	},
	master: {
		title: "ManagersApp Enterprise",
		price: {
			monthly: "Contact Sales",
			showDollar: false,
			free: false
		},
		benefits: [
			"Access to All Modules",
			"Ability to Customize Modules",
			"Priority Support",
		],
	},
};
